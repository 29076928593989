import React from 'react';
import { Box, Typography, IconButton, Link } from '@mui/material';
import { Facebook, LinkedIn } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'; // X logo

function Footer() {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        py: 3, 
        mt: 'auto',
        borderTop: '1px solid #0de3d1',
        backgroundColor: '#0a0b1e',
        width: '100%',
        textAlign: 'center',
        color: '#ffffff'
      }}
    >
      <Box>
        <IconButton component={Link} href="https://www.facebook.com/people/Hive-Forensics-AI/61559411780223/" target="_blank" sx={{ color: '#0de3d1' }}>
          <Facebook />
        </IconButton>
        <IconButton component={Link} href="https://x.com/hiveforensics" target="_blank" sx={{ color: '#0de3d1' }}>
          <FontAwesomeIcon icon={faXTwitter} size="lg" />
        </IconButton>
        <IconButton component={Link} href="https://www.linkedin.com/company/hiveforensics/" target="_blank" sx={{ color: '#0de3d1' }}>
          <LinkedIn />
        </IconButton>
      </Box>
      <Typography variant="body2" align="center" sx={{ color: '#ffffffa6' }}>
        {'Copyright © '}
        {new Date().getFullYear()}
        {' Hive Forensics AI Inc.'}
      </Typography>
    </Box>
  );
}

export default Footer;
