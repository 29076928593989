import React from 'react';
import { Box, Grid, Typography, Card, CardContent, CardActions, Button, Tooltip } from '@mui/material';
import { Security, Search, Assessment, VerifiedUser, Shield, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const features = [
  {
    icon: <Security fontSize="large" sx={{ color: '#0de3d1' }} />,
    title: "Dark Web Monitoring",
    description: "Our API scans the dark web to help you identify and block fraudulent identities, safeguarding your onboarding process from potential threats."
  },
  {
    icon: <Search fontSize="large" sx={{ color: '#0de3d1' }} />,
    title: "Identity Card Forensics",
    description: "Ensures the authenticity of identity cards during onboarding by analyzing critical security features and details."
  },
  {
    icon: <Assessment fontSize="large" sx={{ color: '#0de3d1' }} />,
    title: "AI Risk Assessment",
    description: "Leverages AI to assess and mitigate the risks associated with identity cards, enhancing your protection against fraud."
  },
  {
    icon: <VerifiedUser fontSize="large" sx={{ color: '#0de3d1' }} />,
    title: "Verified Identity Assurance",
    description: "Provides assurance that the identities being onboarded are legitimate, reducing the risk of fraud and identity theft."
  },
  {
    icon: <Shield fontSize="large" sx={{ color: '#0de3d1' }} />,
    title: "Comprehensive Security",
    description: "Our API adds a robust layer of security to your system, ensuring that only verified and trustworthy identities are allowed through."
  },
  {
    icon: <Visibility fontSize="large" sx={{ color: '#0de3d1' }} />,
    title: "Real-Time Monitoring",
    description: "Offers continuous, real-time monitoring of identity verification processes to catch and prevent fraud as it happens."
  }
];

function Features() {
  const navigate = useNavigate();

  return (
    <Box sx={{ py: 10, px: 4, backgroundColor: '#0a0b1e', color: '#ffffff', textAlign: 'center' }}>
      <Typography 
        variant="h4" 
        component="h2" 
        gutterBottom 
        sx={{ 
          fontFamily: 'monospace', 
          color: '#0de3d1', 
          mb: 6,
          fontSize: { xs: '2rem', md: '2.5rem' },
          letterSpacing: 2,
        }}
      >
        Why Choose Our API?
      </Typography>
      <Grid container spacing={6} justifyContent="center">
        {features.map((feature, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card 
              sx={{ 
                height: '100%', 
                backgroundColor: '#12132b', 
                color: '#ffffff', 
                boxShadow: '0px 0px 20px rgba(13, 227, 209, 0.2)',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  boxShadow: '0px 0px 40px rgba(13, 227, 209, 0.4)',
                  transform: 'translateY(-10px)',
                },
                borderRadius: 2,
              }}
            >
              <CardContent sx={{ textAlign: 'center' }}>
                {feature.icon}
                <Typography 
                  variant="h6" 
                  component="h3" 
                  gutterBottom 
                  sx={{ 
                    fontFamily: 'monospace', 
                    color: '#0de3d1',
                    mt: 2,
                  }}
                >
                  {feature.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: '#ffffffa6', 
                    fontFamily: 'monospace',
                    mb: 2,
                  }}
                >
                  {feature.description}
                </Typography>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <Tooltip title="Learn More" arrow>
                  <Button 
                    variant="outlined" 
                    sx={{ 
                      color: '#0de3d1', 
                      borderColor: '#0de3d1', 
                      borderRadius: 20, 
                      px: 3, 
                      py: 1,
                      transition: 'background-color 0.3s ease, transform 0.3s ease',
                      '&:hover': {
                        backgroundColor: '#0de3d1',
                        color: '#0a0b1e',
                        transform: 'translateY(-5px)',
                      },
                    }}
                    onClick={() => navigate('/contact')}
                  >
                    Learn More
                  </Button>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Features;
