import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Alert } from '@mui/material';

function ContactUs() {
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Normally, you would submit the form data to Formspree here
    const form = e.target;
    const data = new FormData(form);

    fetch(form.action, {
      method: form.method,
      body: data,
      headers: {
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          setSubmitted(true);
          form.reset();
        } else {
          alert('Oops! There was a problem with your submission.');
        }
      })
      .catch((error) => {
        alert('Oops! There was a problem with your submission.');
      });
  };

  return (
    <Box 
      sx={{ 
        backgroundColor: '#0a0b1e',
        color: '#ffffff',
        py: 8,
        px: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      <Box
        component="form"
        action="https://formspree.io/f/mvgplevk" // Replace with your Formspree form ID
        method="POST"
        onSubmit={handleSubmit}
        sx={{ 
          maxWidth: '600px',
          width: '100%',
          backgroundColor: '#12132b',
          padding: 4,
          borderRadius: 2,
          boxShadow: '0px 0px 20px rgba(13, 227, 209, 0.2)',
        }}
      >
        <Typography variant="h4" component="h1" align="center" gutterBottom sx={{ color: '#0de3d1', fontFamily: 'monospace' }}>
          Contact Us
        </Typography>
        {submitted ? (
          <Alert severity="success" sx={{ mb: 4 }}>
            Thank you for your submission! One of our agents will get back to you shortly to start the onboarding process.
          </Alert>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                variant="outlined"
                required
                InputLabelProps={{
                  style: { color: '#ffffffa6' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#0de3d1',
                    },
                    '&:hover fieldset': {
                      borderColor: '#0de3d1cc',
                    },
                  },
                  input: { color: '#ffffff' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                variant="outlined"
                required
                InputLabelProps={{
                  style: { color: '#ffffffa6' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#0de3d1',
                    },
                    '&:hover fieldset': {
                      borderColor: '#0de3d1cc',
                    },
                  },
                  input: { color: '#ffffff' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company Name"
                name="company"
                variant="outlined"
                required
                InputLabelProps={{
                  style: { color: '#ffffffa6' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#0de3d1',
                    },
                    '&:hover fieldset': {
                      borderColor: '#0de3d1cc',
                    },
                  },
                  input: { color: '#ffffff' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company Website"
                name="website"
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#ffffffa6' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#0de3d1',
                    },
                    '&:hover fieldset': {
                      borderColor: '#0de3d1cc',
                    },
                  },
                  input: { color: '#ffffff' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                type="tel"
                variant="outlined"
                InputLabelProps={{
                  style: { color: '#ffffffa6' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#0de3d1',
                    },
                    '&:hover fieldset': {
                      borderColor: '#0de3d1cc',
                    },
                  },
                  input: { color: '#ffffff' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Message"
                name="message"
                variant="outlined"
                multiline
                rows={4}
                required
                InputLabelProps={{
                  style: { color: '#ffffffa6' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#0de3d1',
                    },
                    '&:hover fieldset': {
                      borderColor: '#0de3d1cc',
                    },
                  },
                  textarea: { color: '#ffffff' },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                fullWidth 
                sx={{ 
                  backgroundColor: '#0de3d1', 
                  color: '#0a0b1e',
                  '&:hover': { backgroundColor: '#0de3d1cc' },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

export default ContactUs;
