import React from 'react';
import { Box, Typography, Button } from '@mui/material';

function Home() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Flex direction changes based on screen size
        justifyContent: "space-around",
        alignItems: "center",
        textAlign: "center",
        backgroundColor: "#0a0b1e",
        px: 2,
        color: "#ffffff",
        padding: { xs: 4, md: 8 }, // Adding padding for better spacing
      }}
    >
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/banner.webp`} // Using the image from the public directory
        alt="Cybersecurity graphic"
        sx={{
          width: { xs: "100%", md: "50%" }, // Responsive width
          maxWidth: "500px", // Max width for better control
          marginBottom: { xs: 4, md: 0 }, // Spacing adjustments for different screen sizes
        }}
      />
      <Box sx={{ maxWidth: "500px", textAlign: "left" }}>
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            fontFamily: "monospace",
            color: "#0de3d1",
            fontSize: { xs: "2rem", md: "3rem" },
          }}
        >
          Protect Your Business with AI-Powered Identity Verification
        </Typography>
        <Typography
          variant="h6"
          component="p"
          gutterBottom
          sx={{ color: "#ffffffa6", fontSize: { xs: "1rem", md: "1.25rem" } }}
        >
          Our API is designed to seamlessly integrate with your existing system,
          adding an extra layer of security by detecting fake identity cards,
          performing forensic analysis, and assessing risk with AI. It can also
          be used as a standalone solution to enhance your onboarding process.
        </Typography>

        <Button
          variant="contained"
          size="large"
          sx={{
            backgroundColor: "#0de3d1",
            color: "#0a0b1e",
            ":hover": { backgroundColor: "#0de3d1cc" },
          }}
          href="https://api.hiveforensics.com"
          target="_blank"
        >
          Documentation
        </Button>
      </Box>
    </Box>
  );
}

export default Home;

