import React from 'react';
import { Box, Typography, Button, Grid, Card, CardContent, CardActions, Tooltip } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';


const tiers = [
  {
    title: 'Starter',
    price: '$199/month',
    description: [
      'Up to 100 identity verifications/month',
      'Basic Dark Web Monitoring for compromised or stolen IDs',
      'AI-powered detection of fraudulent or risky ID cards',
      'Email Support for assistance and queries',
    ],
    buttonText: 'Get Started',
    buttonVariant: 'outlined',
    link: 'https://buy.stripe.com/00gfZOfLlcjj0sE8wU',
  },
  {
    title: 'Business',
    subheader: 'Most popular',
    price: '$499/month',
    description: [
      'Up to 500 identity verifications/month',
      'Enhanced Dark Web Monitoring to identify high-risk and suspicious identities',
      'Advanced AI Risk Assessment to prevent onboarding with fake or stolen IDs',
      'Priority Email Support for faster response times',
    ],
    buttonText: 'Sign Up Now',
    buttonVariant: 'contained',
    link: 'https://buy.stripe.com/7sI4h6dDderr3EQ5kJ',
  },
  {
    title: 'Enterprise',
    price: 'Contact Us',
    description: [
      'Unlimited identity verifications',
      'Real-time Dark Web Monitoring with immediate alerts for newly compromised identities',
      'AI-driven analysis tailored for detecting complex fraud schemes',
      'Dedicated Support for personalized service and expert consultation',
    ],
    buttonText: 'Contact Sales',
    buttonVariant: 'outlined',
    link: '/contact',  // Replace with your actual contact page URL
  },
];

function Pricing() {
  const navigate = useNavigate();

  const handleButtonClick = (link) => {
    if (link.startsWith('http')) {
      window.location.href = link;
    } else {
      navigate(link);
    }
  };

  return (
    <Box 
      sx={{ 
        backgroundColor: '#0a0b1e',
        color: '#ffffff',
        py: 10,
        px: 4,
        textAlign: 'center',
      }}
    >
      <Typography 
        variant="h3" 
        component="h2" 
        gutterBottom 
        sx={{ 
          color: '#0de3d1', 
          fontFamily: 'monospace',
          mb: 6,
          fontSize: { xs: '2rem', md: '3rem' },
          letterSpacing: 2,
        }}
      >
        Choose Your Plan
      </Typography>
      <Grid container spacing={6} justifyContent="center">
        {tiers.map((tier) => (
          <Grid item key={tier.title} xs={12} sm={6} md={4}>
            <Card 
              sx={{ 
                backgroundColor: '#12132b', 
                color: '#ffffff',
                boxShadow: '0px 0px 20px rgba(13, 227, 209, 0.2)',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  boxShadow: '0px 0px 40px rgba(13, 227, 209, 0.4)',
                  transform: 'translateY(-10px)',
                },
                borderRadius: 2,
              }}
            >
              <CardContent>
                <Typography 
                  component="h4" 
                  variant="h5" 
                  align="center" 
                  sx={{ 
                    color: '#0de3d1', 
                    fontFamily: 'monospace', 
                    textTransform: 'uppercase',
                    mb: 2,
                  }}
                >
                  {tier.title}
                </Typography>
                {tier.subheader && (
                  <Typography 
                    variant="subtitle1" 
                    align="center" 
                    sx={{ 
                      color: '#ffffffa6', 
                      mb: 2, 
                      fontStyle: 'italic',
                    }}
                  >
                    {tier.subheader}
                  </Typography>
                )}
                <Typography 
                  variant="h4" 
                  align="center" 
                  sx={{ 
                    color: '#ffffff', 
                    fontFamily: 'monospace',
                    mb: 4,
                  }}
                >
                  {tier.price}
                </Typography>
                <ul style={{ listStyle: 'none', padding: 0 }}>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                      sx={{ 
                        color: '#ffffffa6', 
                        fontFamily: 'monospace', 
                        mb: 1,
                        '&:before': {
                          content: '"• "',
                          color: '#0de3d1',
                        },
                      }}
                    >
                      {line}
                    </Typography>
                  ))}
                </ul>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center', mt: 2 }}>
                <Tooltip title={`Learn more about our ${tier.title} plan`} arrow>
                  <Button 
                    fullWidth
                    variant={tier.buttonVariant}
                    endIcon={<ArrowForwardIcon />}
                    sx={{
                      backgroundColor: tier.buttonVariant === 'contained' ? '#0de3d1' : 'transparent',
                      color: tier.buttonVariant === 'contained' ? '#0a0b1e' : '#0de3d1',
                      borderColor: '#0de3d1',
                      borderRadius: 20,
                      px: 3,
                      py: 1,
                      fontSize: '1rem',
                      transition: 'background-color 0.3s ease, transform 0.3s ease',
                      '&:hover': {
                        backgroundColor: tier.buttonVariant === 'contained' ? '#0de3d1cc' : 'transparent',
                        borderColor: '#0de3d1cc',
                        transform: 'translateY(-5px)',
                      },
                    }}
                    onClick={() => handleButtonClick(tier.link)}
                  >
                    {tier.buttonText}
                  </Button>
                </Tooltip>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Pricing;

