import React from 'react';
import { Container, Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import Features from './components/Feature';
import Pricing from './components/Pricing';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <Box sx={{ backgroundColor: '#0a0b1e', color: '#ffffff', minHeight: '100vh' }}>
        <Header />
        <Container maxWidth="lg">
          <Routes>
            {/* Render the full page for the main route */}
            <Route path="/" element={
              <>
                <Home />
                <Features />
                <Pricing />
                <Footer />
              </>
            } />
            {/* Separate route for the Contact Us page */}
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
        </Container>
      </Box>
    </Router>
  );
}

export default App;
