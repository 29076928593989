import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

function Header() {
  const [text] = useTypewriter({
    words: ["AI-Powered Security.", "Dark Web Monitoring.", "Advanced Identity Verification.", "Protect Your Business."],
    loop: 0, // Infinite loop
    typeSpeed: 50,
    deleteSpeed: 30,
    delaySpeed: 1000,
  });

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '50vh',
        textAlign: 'center',
        py: 2,
        backgroundColor: 'rgba(10, 11, 30, 0.85)', // Semi-transparent dark overlay
        color: '#0de3d1', // Consistent with your neon-blue theme
        position: 'relative',
        zIndex: 1,
        backgroundImage: 'url("/background.webp")', // Path to the background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          height: '100px', // Height of the gradient
          background: 'linear-gradient(to bottom, rgba(10, 11, 30, 0) 0%, rgba(10, 11, 30, 1) 100%)',
          zIndex: 2,
        },
      }}
    >
      <Typography 
        variant="h3" 
        component="h1" 
        sx={{ 
          fontFamily: 'monospace', 
          fontSize: { xs: '2.5rem', md: '4rem' }, 
          fontWeight: 'bold',
          letterSpacing: 2,
          textShadow: '0 0 12px rgba(13, 227, 209, 0.8)',
          mb: 2,
        }}
      >
        {text}
        <Cursor cursorStyle="|" cursorColor="#0de3d1" />
      </Typography>
      <Typography 
        variant="h6" 
        component="p" 
        sx={{ 
          fontFamily: 'monospace', 
          fontSize: { xs: '1rem', md: '1.25rem' },
          color: '#ffffffb3', // Slightly muted white for a subtle look
          maxWidth: '600px',
          mx: 'auto',
        }}
      >
        Secure your digital onboarding with cutting-edge AI technology designed to protect your business from fraudulent activities.
      </Typography>
    </Box>
  );
}

export default Header;

